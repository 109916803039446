import 'antd/dist/antd.css';
import Homepage from 'components/pages/PublicPages/Home/Home';
import { CLIENT_DOMAIN } from 'constants/index';
import { ROUTES_API } from 'constants/routes';

import Axios from 'services/Axios/Axios';

const home = () => (
  <Homepage />
);
home.title = 'home3Title';
let initData;
home.getInitialProps = async (context) => {
  const { req } = context;
  const domain = req?.headers?.host;
  if (domain) {
    try {
      Axios.addHeader(CLIENT_DOMAIN, domain);
      await Axios.get(`${ROUTES_API.GET_BROWSE_CATEGORY}`, (status, data) => {
        initData = data.content;
      });
    } catch (error) {
      initData = {};
    }
  }
  return { homeData: initData };
};
export default home;
