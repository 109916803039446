/* eslint-disable react/no-children-prop */
import RecentlyVHome from 'components/pages/PublicPages/Home/HomeTab/RecentlyVHome';
import TabContent from 'components/organisms/TabContent/TabContent';
import { getTransText } from 'common/translate';
import React, { useContext, useLayoutEffect, useState } from 'react';
import ContentSection from 'components/organisms/ContentSection/ContentSection';
import { isEmpty } from 'lodash';
import { getCookies } from 'helpers/cookies';
import { ACCESS_TOKEN, GET_SETTING, ROUTES } from 'constants/index';
import { Tabs } from 'antd';
import TrendingFrame from 'components/molecules/TrendingFrame/TrendingFrame';
import MainContext from 'stores/context/MainContexts';
import { useRouter } from 'next/router';
import { getStorage } from 'helpers/storage';
import RecommendedHome from './RecommendedHome';
import CategoriesHome from './CategoriesHome';

const { TabPane } = Tabs;
const HomeTab = () => {
  const { initDataProvider } = useContext(MainContext);
  const [isRecentyleView, setIsRecentyleView] = useState(false);
  const [activeKey, setActiveKey] = useState('1');
  const setTabCollection = (key) => {
    setActiveKey(key);
  };
  const router = useRouter();
  useLayoutEffect(() => {
    if (!isEmpty(getCookies(ACCESS_TOKEN))) {
      setIsRecentyleView(true);
    }
    const detectShowDropdown = initDataProvider?.getSettings?.show_hide_header
      ?? getStorage(GET_SETTING)?.show_hide_header;
    if (detectShowDropdown?.hide_image) {
      router.push({ pathname: detectShowDropdown.hide_footage ? ROUTES.AUDIO : ROUTES.FOOTAGE });
    }
  }, [getStorage(GET_SETTING)?.show_hide_header?.hide_image]);
  return (
    <TabContent
      activeKey={activeKey}
      setTabCollection={setTabCollection}
    >
      <TabPane tab={getTransText('recommended')} key="1">
        <RecommendedHome />
        <div className="divide-space" />
        <div className="container-wrapper">
          <ContentSection title={getTransText('trendingKeyWord')} className="text-center">
            <TrendingFrame />
          </ContentSection>
        </div>
      </TabPane>
      <TabPane tab={getTransText('categoryImage')} key="2">
        <div className="container-wrapper">
          <CategoriesHome />
        </div>
        <div className="divide-space" />
      </TabPane>
      {isRecentyleView && (
        <TabPane tab={getTransText('recentViewed')} key="3">
          <RecentlyVHome />
        </TabPane>
      )}
    </TabContent>
  );
};
export default HomeTab;
