import React, { useContext, useEffect, useState } from 'react';
import CustomJustifiedLayout from 'components/molecules/CustomJustifiedLayout/CustomJustifiedLayout';
import MainContext from 'stores/context/MainContexts';
import { RECOMMENDED_MAX_ROWS_DESKTOP, RECOMMENDED_MAX_ROWS_MOBILE } from 'constants/index';

const RecommendedHome = () => {
  const { initDataProvider } = useContext(MainContext);
  const [geometry, setGeometry] = useState([]);
  const [listImage, setListImage] = useState([]);
  const geometryTemp = [];

  useEffect(() => {
    initDataProvider?.recommendedImages?.photos?.forEach((item) => {
      geometryTemp.push(item.width / item.height);
    });
    setListImage(initDataProvider?.recommendedImages?.photos);
    setGeometry(geometryTemp);
  }, [initDataProvider?.recommendedImages?.photos]);
  return (
    <>
      <CustomJustifiedLayout
        GeometriesDesktop={geometry}
        GeometriesTablet={geometry}
        GeometriesMobile={geometry}
        sizePadding={{
          paddingTop: 0,
          sizePadding: 1441,
          paddingLeft: 100,
          paddingRight: 115,
          exceptContainerWidth: 1,
        }}
        listImage={listImage}
        contentLoading={initDataProvider?.recommendedImagesLoading}
        GeometriesDesktopLoader={[1.4, 1.4, 1.2, 1.2,
          1.4, 1.6, 1.4, 1.4, 1.4, 1.6, 1.2, 1.4, 1.2, 1.2, 1.2]}
        GeometriesTabletLoader={[1.4, 1.4, 1.6, 1.2, 1.4, 1.6, 1.6]}
        GeometriesMobileLoader={[1.2, 1.2, 1.2, 1.2, 1.2, 1.2]}
        maxNumRowsDesktop={RECOMMENDED_MAX_ROWS_DESKTOP}
        maxNumRowsMobile={RECOMMENDED_MAX_ROWS_MOBILE}
      />
    </>
  );
};
export default RecommendedHome;
