import CustomJustifiedLayout from 'components/molecules/CustomJustifiedLayout/CustomJustifiedLayout';
import { TYPE_LANDING_PAGE } from 'constants/index';
import { API_ROUTES } from 'constants/routes';
import React, { useEffect, useState } from 'react';
import Axios from 'services/Axios/Axios';

const RecentlyVHome = () => {
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [geometriesImage, setGeometriesImage] = useState([]);
  const getData = async () => {
    await Axios.get(`${API_ROUTES.GET_RECENTLY_VIEWED}?${TYPE_LANDING_PAGE.PHOTO}`, (status, data) => {
      setListData(data?.content?.items);
      const geometry = [];
      data?.content?.items?.forEach((item) => {
        geometry.push(item?.ratio);
      });
      setGeometriesImage(geometry);
      setLoading(false);
    });
  };
  useEffect(() => {
    getData();
  }, []);

  // To render full image
  return (
    <CustomJustifiedLayout
      GeometriesDesktop={geometriesImage}
      GeometriesTablet={geometriesImage}
      GeometriesMobile={geometriesImage}
      sizePadding={{
        paddingTop: 0,
        sizePadding: 1441,
        paddingLeft: 100,
        paddingRight: 115,
        exceptContainerWidth: 1,
      }}
      recentlyViewed
      showWidows
      listImage={listData}
      contentLoading={loading}
      GeometriesDesktopLoader={[0.7, 1.4, 0.8, 1.2, 0.6, 0.6,
        1.2, 0.8, 1.2, 0.7, 0.7, 1.4, 0.8, 1.2, 0.6]}
      GeometriesTabletLoader={[0.9, 0.6, 1, 0.7, 1.2, 0.5, 0.7, 1, 1.3, 0.7, 0.7, 0.6]}
      GeometriesMobileLoader={[1, 1, 1, 1, 1, 1, 1]}
    />
  );
};
export default RecentlyVHome;
