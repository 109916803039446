import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import ImageHoverEffect from 'components/atoms/ImageHoverEffect/ImageHoverEffect';
import Axios from 'services/Axios/Axios';
import { API_ROUTES, ROUTES } from 'constants/routes';
import { isEmpty } from 'lodash';
import ContentLoaderImage from 'components/molecules/ContentLoader/ContentLoaderImage';
import Categories from 'components/molecules/Categories/Categories';
import ContentSection from 'components/organisms/ContentSection/ContentSection';
import { getTransText } from 'common/translate';
import { getCookies } from 'helpers/cookies';
import { LANG } from 'constants/index';

const CategoriesHome = () => {
  const [categoryImage, setCategoryImage] = useState([]);
  const [category, setCategory] = useState([]);
  const getCategory = async () => {
    await Axios.get(`${API_ROUTES.GET_CATEGORY}?image_type=1`, (status, data) => {
      setCategoryImage(data?.content?.featured_photos);
      setCategory(data?.content?.categories);
    });
  };
  useEffect(() => {
    getCategory();
  }, [getCookies(LANG)]);
  return (
    <>
      {isEmpty(categoryImage) ? (
        <ContentLoaderImage
          data={12}
          gutter={[4, 4]}
          spanSize={[12, 12, 6, 6, 6, 6]}
          aspectRatio="aspect-ratio-img"
        />
      )
        : (
          <>
            <Row className="homepage-image" gutter={[4, 4]}>
              <Col
                xs={12}
                xl={12}
                sm={24}
                lg={12}
                md={12}
                className="image-frame-big"
              >
                <ImageHoverEffect
                  data={categoryImage[0]?.src}
                  className="image-frame-big"
                  content={categoryImage[0]?.title}
                  link={`${ROUTES.SEARCH_IMAGE}?keyword=${categoryImage[0]?.title}&mediaType=1`}
                />
              </Col>
              <Col
                xs={24}
                xl={12}
                sm={24}
                lg={12}
                md={12}
              >
                <Row gutter={[4, 4]}>
                  <Col
                    xs={12}
                    xl={12}
                    sm={12}
                    lg={12}
                    md={12}
                  >
                    <ImageHoverEffect
                      data={categoryImage[1]?.src}
                      className="aspect-ratio-img"
                      content={categoryImage[1]?.title}
                      link={`${ROUTES.SEARCH_IMAGE}?keyword=${categoryImage[1]?.title}&mediaType=1`}
                    />
                  </Col>
                  <Col
                    xs={12}
                    xl={12}
                    sm={12}
                    lg={12}
                    md={12}
                  >
                    <ImageHoverEffect
                      data={categoryImage[2]?.src}
                      className="aspect-ratio-img"
                      content={categoryImage[2]?.title}
                      link={`${ROUTES.SEARCH_IMAGE}?keyword=${categoryImage[2]?.title}&mediaType=1`}
                    />
                  </Col>
                  <Col
                    xs={12}
                    xl={12}
                    sm={12}
                    lg={12}
                    md={12}
                  >
                    <ImageHoverEffect
                      data={categoryImage[3]?.src}
                      className="aspect-ratio-img"
                      content={categoryImage[3]?.title}
                      link={`${ROUTES.SEARCH_IMAGE}?keyword=${categoryImage[3]?.title}&mediaType=1`}
                    />
                  </Col>
                  <Col
                    xs={12}
                    xl={12}
                    sm={12}
                    lg={12}
                    md={12}
                  >
                    <ImageHoverEffect
                      data={categoryImage[4]?.src}
                      className="aspect-ratio-img"
                      content={categoryImage[4]?.title}
                      link={`${ROUTES.SEARCH_IMAGE}?keyword=${categoryImage[4]?.title}&mediaType=1`}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="homepage-image" gutter={[4, 4]}>
              <Col
                xs={24}
                xl={12}
                sm={12}
                lg={12}
                md={12}
              >
                <Row gutter={[4, 4]}>
                  <Col
                    xs={12}
                    xl={12}
                    sm={24}
                    lg={12}
                    md={12}
                  >
                    <ImageHoverEffect
                      data={categoryImage[5]?.src}
                      className="aspect-ratio-img"
                      content={categoryImage[5]?.title}
                      link={`${ROUTES.SEARCH_IMAGE}?keyword=${categoryImage[5]?.title}&mediaType=1`}
                    />
                  </Col>
                  <Col
                    xs={12}
                    xl={12}
                    sm={24}
                    lg={12}
                    md={12}
                  >
                    <ImageHoverEffect
                      data={categoryImage[6]?.src}
                      className="aspect-ratio-img"
                      content={categoryImage[6]?.title}
                      link={`${ROUTES.SEARCH_IMAGE}?keyword=${categoryImage[6]?.title}&mediaType=1`}
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                xs={24}
                xl={12}
                sm={12}
                lg={12}
                md={12}
              >
                <Row gutter={[4, 0]}>
                  <Col
                    xs={12}
                    xl={12}
                    sm={24}
                    lg={12}
                    md={12}
                  >
                    <ImageHoverEffect
                      data={categoryImage[7]?.src}
                      className="aspect-ratio-img"
                      content={categoryImage[7]?.title}
                      link={`${ROUTES.SEARCH_IMAGE}?keyword=${categoryImage[7]?.title}&mediaType=1`}
                    />
                  </Col>
                  <Col
                    xs={12}
                    xl={12}
                    sm={24}
                    lg={12}
                    md={12}
                  >
                    <ImageHoverEffect
                      data={categoryImage[8]?.src}
                      className="aspect-ratio-img"
                      content={categoryImage[8]?.title}
                      link={`${ROUTES.SEARCH_IMAGE}?keyword=${categoryImage[8]?.title}&mediaType=1`}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      <div className="divide-space" />
      <ContentSection title={getTransText('category')}>
        <Categories data={category} />
      </ContentSection>

    </>
  );
};
export default CategoriesHome;
