import { Layout } from 'antd';
// import { getTransText } from 'common/translate';
import Banner from 'components/molecules/Banner/Banner';
// import FAQ from 'components/molecules/FAQ/FAQ';
// import ContentSection from 'components/organisms/ContentSection/ContentSection';
import HomeTab from 'components/pages/PublicPages/Home/HomeTab/HomeTab';
import React from 'react';

const Home = () => (
  <Layout>
    <Banner />
    <HomeTab />
    {/* <div className="divide-space" /> */}
    {/* <div className="container-wrapper">
      <ContentSection
        title={getTransText('FAQ')}
      >
        <FAQ />
      </ContentSection>
    </div> */}
    <div className="divide-space" />
  </Layout>
);
export default Home;
