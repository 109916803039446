import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Row } from 'antd';
import ImageHoverEffect from 'components/atoms/ImageHoverEffect/ImageHoverEffect';
import Axios from 'services/Axios/Axios';
import { API_ROUTES, ROUTES } from 'constants/routes';
import ContentLoaderImage from 'components/molecules/ContentLoader/ContentLoaderImage';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { getTransText } from 'common/translate';
import { getCookies } from 'helpers/cookies';
import { LANG } from 'constants/index';

const TrendingFrame = () => {
  const [getValue, setValue] = useState([]);
  const [trendingKeyWord, setTrendingKeyWord] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMoreLess, setShowMoreLess] = useState(false);
  const apiController = new AbortController();
  const observer = apiController.signal;

  const myRef = useRef(null);

  const getData = async () => {
    setLoading(true);
    await Axios.getLastApiCall(`${API_ROUTES.GET_TRENDING_KEYWORDS}`, (status, data) => {
      const newArr = data?.content.slice(0, 24);
      setTrendingKeyWord(newArr);
      setValue(data?.content);
      setLoading(false);
    }, observer);
  };
  const getLenghtValue = () => {
    const newArr = getValue.slice(0, 24);
    setTrendingKeyWord(newArr);
  };
  const onShowMoreLess = () => {
    if (!showMoreLess) {
      setTrendingKeyWord(getValue);
      setShowMoreLess(true);
    } else {
      setShowMoreLess(false);
      setTrendingKeyWord(getValue.slice(0, 24));
      myRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(() => {
    getData();
    return () => { if (apiController) apiController.abort(); };
  }, [getCookies(LANG)]);
  useEffect(() => {
    getLenghtValue();
  }, [getValue]);
  return (
    <>
      {isEmpty(trendingKeyWord) || loading ? (
        <ContentLoaderImage
          data={16}
          gutter={[4, 4]}
          spanSize={[24, 12, 6, 6, 6, 6]}
          aspectRatio="aspect-ratio-img"
        />
      ) : (
        <>
          <Row ref={myRef} className="homepage-image" gutter={[4, 4]}>
            {trendingKeyWord?.map((item, index) => (
              <Col xs={24} xl={6} sm={12} lg={6} md={6} key={index.toString()}>
                <ImageHoverEffect
                  dynamicData
                  data={item?.image}
                  className="aspect-ratio-trending-kw"
                  content={item?.keyword}
                  link={`${ROUTES.SEARCH_IMAGE}?keyword=${item?.keyword}&mediaType=1`}
                />
              </Col>
            ))}
          </Row>
          <Row className="trending-kw-more">
            <Button className="no-border more-btn-trending" icon={!showMoreLess ? <DownOutlined /> : <UpOutlined />} onClick={onShowMoreLess}>
              {!showMoreLess ? getTransText('seeMore') : getTransText('seeLess')}
            </Button>
          </Row>
        </>
      )}
    </>
  );
};
export default TrendingFrame;
